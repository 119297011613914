<script lang="ts">
	export let data;
</script>

<svelte:head>
	<title>{data.context.title}</title>
	<meta name="description" content={data.context.og.description} />
	<meta property="og:title" content={data.context.og.title} />
	<meta property="og:description" content={data.context.og.description} />
	<meta property="og:url" content={data.context.og.url} />
	<meta property="og:type" content="website" />
	<meta property="og:image" content={data.context.og.image.url} />
	<meta property="og:image:alt" content={data.context.og.image.alt} />
	<meta property="og:image:type" content={data.context.og.image.type} />
	<meta property="og:image:width" content={data.context.og.image.width} />
	<meta property="og:image:height" content={data.context.og.image.height} />
	<meta name="twitter:title" content={data.context.og.title} />
	<meta name="twitter:description" content={data.context.og.description} />
	<meta name="twitter:card" content="summary_large_image" />
	<meta name="twitter:image" content={data.context.og.image.url} />
	<meta name="twitter:image:alt" content={data.context.og.image.alt} />
</svelte:head>

<main>
	<ul class="columns-1 md:columns-2 lg:columns-3 w-full container mx-auto">
		{#each data.content as { pathname, published_time, title, og: { description } }}
			<li class="mb-4 break-inside-avoid">
				<article class="card card-bordered bg-base-200 shadow-sm">
					<div class="card-body prose prose-lg">
						<h2>{title}</h2>
						<p>{description}</p>
						<div class="card-actions">
							<a href="/{pathname}" class="link">Leggi</a>
						</div>
					</div>
				</article>
			</li>
		{/each}
	</ul>
</main>
